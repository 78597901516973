<template>
  <div>
    <b-row>
      <b-col v-if="checkPermission('view', 'action', 'banner-dashboard') && banner_list.length > 0"  sm="12" md="12" lg="4" xl="3" class="mt-2">
        <b-carousel
            img-width="1000"
            img-height=1000
            id="carousel-no-animation"
            indicators
            no-animation
            @click="urlLink($event)"
        >
          <template v-for="(item, index) in banner_list" >
            <a  v-if="item.url" :href="`https://${item.url}`" target="_blank" :key="index">
              <b-carousel-slide
                  img-width="1000"
                  img-height=1000
                  :img-src="item.image"

              ></b-carousel-slide>
            </a>
            <a v-else href="javascript:void (0)" :key="index">
              <b-carousel-slide
                  img-width="1000"
                  img-height=1000
                  :img-src="item.image"

              ></b-carousel-slide>
            </a>
          </template>

        </b-carousel>
      </b-col>
      <b-col sm="12" md="12" :lg="checkPermission('view', 'action', 'banner-dashboard') && banner_list.length > 0 ? 8 : 12" :xl="checkPermission('view', 'action', 'banner-dashboard') && banner_list.length > 0 ? 9 : 12" class="mt-2">
        <b-card style="min-height: 400px;">
          <div class="title-box">
            {{ $t('dashboard.overview_credit') }}
          </div>
          <div class="mt-3">
            <b-row>
              <b-col sm="12" md="12" lg="4" class="mt-2">
                <div>
                  <b-card class="credit-balance">
                    <b-row>
                      <b-col sm="12" md="12" lg="12" class="mt-2">
                        <img src="@/assets/images/icon/ic_credit.svg" alt="credit" width="80">
                      </b-col>
                      <b-col sm="12" md="12" lg="12" class="mt-5">
                        <div>
                          {{ $t('dashboard.credit_balance') }}
                        </div>
                        <div class="credit-total">
                          {{ changeFormalNumber(userInfo?.sms_unit) || 0 }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="4" class="mt-2">
                <div>
                  <b-card class="credit-balance-box">
                    <b-row>
                      <b-col sm="12" md="12" lg="12" class="mt-2">
                        <img src="@/assets/images/icon/ic_spent.svg" alt="credit" width="80">
                      </b-col>
                      <b-col sm="12" md="12" lg="12" class="mt-5">
                        <div>
                          {{ $t('dashboard.creditUsed') }}
                        </div>
                        <div class="credit-total">
                          {{ sumTotalCredit(userInfo?.sms_unit, userInfo?.sms_unit_batch) || 0}}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </b-col>
              <b-col sm="12" md="12" lg="4" class="mt-2">
                <div>
                  <b-card class="credit-balance-box">
                    <b-row>
                      <b-col sm="12" md="12" lg="12" class="mt-2">
                        <img src="@/assets/images/icon/icon_total.svg" alt="credit" width="80">
                      </b-col>
                      <b-col sm="12" md="12" lg="12" class="mt-5">
                        <div>
                          {{ $t('dashboard.total_credit_all') }}
                        </div>
                        <div class="credit-total">
                          {{ changeFormalNumber(userInfo?.sms_unit_batch) || 0 }}
                        </div>
                      </b-col>
                    </b-row>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <overlay-page :visible="loading_dashboard">
      <b-card class="mt-2">
        <b-row>
          <b-col align-self="end" sm="12" md="12" lg="8" class="mt-2">
            <div class="title-box">
              {{ $t('dashboard.overview_sent') }}
            </div>
          </b-col>
          <b-col sm="12" md="12" lg="4" class="mt-2">
            <div>
              <label> {{ $t('common.dateTime') }}</label>
              <date-time-range-component v-model="time" type="date" @input="getDataDashboard"/>
            </div>
          </b-col>
        </b-row>
        <div class="mt-4">
          <b-row>
            <b-col sm="12" md="12" lg="6" class="mt-3">
              <b-card class="border">
                <div>
                  <div class="title-box-content">
                    {{ $t('dashboard.sent_campaign') }}
                  </div>
                  <b-row class="mt-3">
                    <b-col sm="12" md="12" lg="6" class="mt-3">
                      <b-card class="credit-balance-campaign">
                        <div class="credit-total">
                          {{ changeFormalNumber(campaign?.total_sent?.count) || 0 }}
                        </div>
                        <div>
                          {{ $t('dashboard.success') }} <span class="text-bold">{{ changeFormalNumber(campaign?.success?.count) || 0 }}</span>
                          {{ $t('dashboard.number') }}
                        </div>
                        <div class="mt-3">
                          <b-progress :value="campaign?.success?.count" :max="campaign?.total_sent?.count"
                                      height="12px"/>
                        </div>
                        <div class="mt-2">
                          <span class="mr-2"> {{ $t('dashboard.success') }}</span>
                          <span
                              class="text-primary text-bold">{{
                              changeNumberToPercent(campaign?.success?.count, campaign?.total_sent?.count)
                            }}</span>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col align-self="end" sm="12" md="12" lg="6" class="mt-3">
                      <div>
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ $t('dashboard.sent') }}
                          </div>
                          <div>
                            <span class="mr-2 text-primary text-bold">{{ changeFormalNumber(campaign?.total_campaign?.sent.total) || 0 }}</span>
                            <span> {{ $t('dashboard.campaign') }}</span>
                          </div>
                        </div>
                        <hr class="mt-2 mb-2"/>
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ $t('dashboard.wait_to_send') }}
                          </div>
                          <div>
                            <span class="mr-2 text-bold">{{ changeFormalNumber(campaign?.total_campaign?.waiting.total) || 0 }}</span>
                            <span> {{ $t('dashboard.campaign') }}</span>
                          </div>
                        </div>
                        <hr class="mt-2 mb-2"/>
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ $t('dashboard.cancel') }}
                          </div>
                          <div>
                            <span class="mr-2 text-danger text-bold">{{
                                changeFormalNumber(campaign?.total_campaign?.cancelled?.total) || 0
                              }}</span>
                            <span> {{ $t('dashboard.campaign') }}</span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
            <b-col  sm="12" md="12" lg="6" class="mt-3">
              <b-card class="border">
                <div>
                  <div class="title-box-content">
                    {{ $t('dashboard.sent_campaign_api') }}
                  </div>
                  <b-row class="mt-3">
                    <b-col sm="12" md="12" lg="6" class="mt-3">
                      <b-card class="credit-balance-campaign">
                        <div class="credit-total">
                          {{ changeFormalNumber(campaign_api?.total_sent?.count) || 0 }}
                        </div>
                        <div>
                          {{ $t('dashboard.success') }} <span class="text-bold"> {{
                            changeFormalNumber(campaign_api?.success?.count) || 0
                          }}</span> {{ $t('dashboard.number') }}
                        </div>
                        <div class="mt-3">
                          <b-progress :value="campaign_api?.success?.count" :max="campaign_api?.total_sent?.count"
                                      height="12px"/>
                        </div>
                        <div class="mt-2">
                          <span class="mr-2"> {{ $t('dashboard.success') }}</span>
                          <span
                              class="text-primary text-bold">{{ changeNumberToPercent(campaign_api?.success?.count, campaign_api?.total_sent?.count) }}</span>
                        </div>
                      </b-card>
                    </b-col>
                    <b-col align-self="end" sm="12" md="12" lg="6" class="mt-3">
                      <div>
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ $t('dashboard.sent') }}
                          </div>
                          <div>
                            <span class="mr-2 text-primary text-bold">{{
                                changeFormalNumber(campaign_api?.total_campaign?.sent.total) || 0
                              }}</span>
                            <span> {{ $t('dashboard.campaign') }}</span>
                          </div>
                        </div>
                        <hr class="mt-2 mb-2"/>
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ $t('dashboard.wait_to_send') }}
                          </div>
                          <div>
                            <span class="mr-2 text-bold">{{ changeFormalNumber(campaign_api?.total_campaign?.waiting?.total) || 0 }}</span>
                            <span> {{ $t('dashboard.campaign') }}</span>
                          </div>
                        </div>
                        <hr class="mt-2 mb-2"/>
                        <div class="d-flex justify-content-between">
                          <div>
                            {{ $t('dashboard.cancel') }}
                          </div>
                          <div>
                          <span class="mr-2 text-danger text-bold">{{
                              changeFormalNumber(campaign_api?.total_campaign?.cancelled?.total) || 0
                            }}</span>
                            <span> {{ $t('dashboard.campaign') }}</span>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
        <div class="mt-3">
          <b-row>
            <b-col sm="12" md="12" lg="6" class="mt-3">
              <b-card class="border">
                <b-row>
                  <b-col align-self="center">
                    <div class="ml-3">
                      <div class="title-box-content">
                        {{ $t('dashboard.quickSend') }}
                      </div>
                      <div class="total-campaign">
                        {{ changeFormalNumber(quick_send?.total_sent?.count) || 0 }}
                      </div>
                      <div>
                        {{ $t('dashboard.success') }} <span class="text-bold">{{ changeFormalNumber(quick_send?.success?.count) || 0 }}</span>
                        {{ $t('dashboard.number') }}
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <vue-apex-charts width="250px" :options="chartOptions" :series="series_quick_send"/>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col sm="12" md="12" lg="6" class="mt-3">
              <b-card class="border">
                <b-row>
                  <b-col align-self="center">
                    <div class="ml-3">
                      <div class="title-box-content d-flex">
                        <div class="mr-2">
                          {{ $t('dashboard.sent_api') }}
                        </div>
                        <div>
                          <img id="tooltip-button-disable" src="@/assets/images/icon/info.svg" alt="info" width="16"
                               class="info-icon-box">
                        </div>
                      </div>
                      <div class="total-campaign">
                        {{ changeFormalNumber(other_api?.total_sent?.count) || 0 }}
                      </div>
                      <div>
                        {{ $t('dashboard.success') }} <span class="text-bold">{{ changeFormalNumber(other_api?.success?.count) || 0 }}</span>
                        {{ $t('dashboard.number') }}
                      </div>
                    </div>
                  </b-col>
                  <b-col>
                    <div>
                      <vue-apex-charts width="250px" :options="chartOptions" :series="series_other_api"/>
                    </div>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </b-card>
    </overlay-page>
    <b-tooltip ref="tooltip" target="tooltip-button-disable" placement="rightbottom" variant="secondary">
      <div class="text-left">
        <div>- SMS OTP</div>
        <div>- SMS API</div>
      </div>
    </b-tooltip>
  </div>
</template>
<script>
import InputComponent from "@/components/common/inputComponentSearch.vue";
import VueApexCharts from "vue-apexcharts";
import moment from "moment/moment";
import {mapState} from "vuex";
import DateTimeRangeComponent from "@/components/common/dateTimeRangeComponent.vue";
import reportApi from "@/repository/reportApi";
import OverlayPage from "@/components/common/overlayPage.vue";
import functions from "@/common/functions";

export default {
  name: "index",
  components: {OverlayPage, DateTimeRangeComponent, VueApexCharts, InputComponent},
  data() {
    return {
      time: [
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T00:00:00'),
        new Date(moment(new Date()).format('YYYY-MM-DD') + 'T23:59:59')
      ],
      form: {
        date_from: null,
        date_to: null
      },
      campaign: null,
      campaign_api: null,
      other_api: null,
      quick_send: null,
      chartOptions: {
        colors: ['#19CBAC'],
        chart: {
          width: 250,
          type: 'radialBar',
        },
        labels: ['Success'],
        responsive: [{
          breakpoint: 180,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'top'
            }
          }
        }]
      },
      loading_dashboard: false,
      banner_list: []
    }
  },
  computed: {
    series_quick_send() {
      const data = this.quick_send?.success?.count / (this.quick_send?.total_sent?.count ? this.quick_send?.total_sent?.count : 1)
      return isNaN(data) ? [0] : [parseInt(data * 100)]
    },
    series_other_api() {
      const data = this.other_api?.success?.count / (this.other_api?.total_sent?.count ? this.other_api?.total_sent?.count : 1)
      return isNaN(data) ? [0] : [parseInt(data * 100)]
    },
    ...mapState({
      theme_ui: state => state.profile.theme_setting,
    }),
    userInfo() {
      return this.$store.getters["profile/getProfile"]
    },
  },
  mounted() {
    this.getDataDashboard()
    this.getSettingBannerDashboard()
  },
  methods: {
    getDataDashboard() {
      this.form.date_from = moment(this.time[0]).format('YYYY-MM-DD') + 'T' + moment(this.time[0]).format('HH:mm:ss') + '.000Z'
      this.form.date_to = moment(this.time[1]).format('YYYY-MM-DD') + 'T' + moment(this.time[1]).format('HH:mm:ss') + '.999Z'
      this.loading_dashboard = true
      reportApi.getDashboard(this.form).then(resp => {
        this.campaign = resp?.campaign
        this.campaign_api = resp?.campaign_api
        this.other_api = resp?.other_api
        this.quick_send = resp?.quick_send
      }).catch(err => {
        throw err
      }).finally(() => {
        this.loading_dashboard = false
      })
    },
    sumTotalCredit(sms_unit = 0, sms_unit_batch = 0) {
      let sum = sms_unit_batch - sms_unit
      return this.changeFormalNumber(sum)
    },
    changeFormalNumber(num) {
      return num?.toLocaleString()
    },
    changeNumberToPercent(success = 0, total_send = 0) {
      let percent = success / total_send
      return isNaN(percent) ? '0%' : parseInt(percent * 100) + '%'
    },
    getSettingBannerDashboard () {
      reportApi.getSettingBannerList().then(resp => {
        const data = _.get(resp, 'data', {})
        this.banner_list = _.map(data, item => {
          return {
            image:  item.image,
            url: item.url
          }
        })
      }).catch(err => {
        throw  err
      })
    },
    checkPermission(permission, type, modules) {
      return functions.userCheckPermission(permission, type, modules)
    },
    urlLink (url) {
      console.log(url, 'url')
    }
  },
}
</script>
<style scoped>
.title-box {
  color: #5C698C;
  font-size: 24px;
  font-weight: 700;
}

.title-box-content {
  color: #5C698C;
  font-size: 20px;
  font-weight: 700;
}

.credit-balance {
  border-radius: 12px;
  background: #ECFFFB;
  min-height: 300px;
  //background-color: fade(var(--primary-color), 1%);

  .credit-total {
    color: var(--primary-color);
    font-size: 36px;
    font-weight: 700;
  }
}

.credit-balance-box {
  border-radius: 12px;
  background: #F8F9FE;
  min-height: 300px;
  //background-color: fade(var(--primary-color), 1%);

  .credit-total {
    color: #5C698C;
    font-size: 36px;
    font-weight: 700;
  }
}

.credit-balance-campaign {
  border-radius: 12px;
  background-color: #F8F9FE;

  .credit-total {
    color: var(--primary-color);
    font-size: 36px;
    font-weight: 700;
  }
}

.progress {
  background-color: #D9D9D9;
  border-radius: 10px;
}

.total-campaign {
  font-size: 32px;
  font-weight: 700;
  color: var(--primary-color);
  margin-top: 5px;
}

.info-icon-box:hover {
  color: var(--primary-color);
  cursor: pointer;
}
#carousel-no-animation::v-deep .carousel-inner {
  border-radius: 14px;
  height: 400px!important;
  max-height: 400px!important;
  background: #FFFFFF;

}
#carousel-no-animation::v-deep .carousel-inner:hover {
  background: #FFFFFF;
  opacity: 0.9;
}
#carousel-no-animation::v-deep img {
  height: 100%;
  width: 100%;
  object-fit: fill!important;
}
#carousel-no-animation::v-deep .carousel-indicators {
  background: #000000 !important;
  width: fit-content;
  justify-self: center;
  border-radius: 15px 15px 15px 15px;
  padding: 0 15px 0 15px;
}
#carousel-no-animation::v-deep .carousel-indicators li {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 8px!important;
  height: 8px!important;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #FFFFFF!important;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: opacity 0.6s ease;
  border-radius: 50%!important;
  opacity: 1;
}
#carousel-no-animation::v-deep .carousel-indicators > .active {
  opacity: 1;
  background-color: var(--primary-color)!important;
}

#carousel-no-animation::v-deep .carousel-item.active, .carousel-item-next, .carousel-item-prev {
  display: contents!important;
}

</style>